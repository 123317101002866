@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


// CONTACT
.content-wrapper {
    .container {
        div.content.page.contact {
            div + br {
                display: none;
            }

            p {
                //&:first-child,
                //&:last-child {
                //    display: none;
                //}
            }


            // 各フォーム
            .wpcf7 {
                form {
                    p {
                        margin-bottom: 30px;

                        .required {
                            color: $error-color;
                        }

                        .wpcf7-form-control-wrap {
                            display: block;
                            margin-top: 8px;

                            input[type=text],
                            input[type=email],
                            textarea {
                                background: white;
                                border: 1px solid $form-border-color;
                                box-shadow: none;
                                color: $base-font-color;

                                padding: .375rem .7rem;
                                width: 100%;
                                max-width: 100%;
                                vertical-align: bottom;

                                @include transition(border-color, .15s);

                                &:focus {
                                    border-color: $form-border-color-focus;
                                }
                            }

                            &.your-name,
                            &.your-email {
                                input {
                                    width: 55%;
                                }
                            }

                            .wpcf7-not-valid-tip {
                                color: $error-color;
                                font-size: 14px;
                                margin-top: 5px;
                            }
                        }

                        // checkbox
                        span[class*="acceptance-"] {
                            display: block;
                            margin: 30px 0;

                            .wpcf7-form-control {
                                .wpcf7-list-item {
                                    position: relative;
                                    margin-left: 0;

                                    input[type=checkbox] {
                                        position: absolute;
                                        margin: 0;
                                        opacity: 0;
                                        z-index: -1;
                                    }

                                    label.acceptance-label {
                                        position: relative;
                                        display: block;
                                        line-height: 18px;
                                        padding: 0 0 0 30px;

                                        &:before,
                                        &:after {
                                            position: absolute;
                                            display: block;
                                            content: "";

                                            box-sizing: border-box;
                                            border-radius: 5px;
                                            width: 20px;
                                            height: 20px;
                                            left: 0;
                                            top: 0;
                                        }

                                        &:before {
                                            border: 1px solid $form-border-color;
                                            @include transition(all, .15s);
                                        }
                                    }

                                    // focus
                                    label.acceptance-label:hover {
                                        &:before {
                                            border: 1px solid rgba(67, 209, 209, .65);
                                        }
                                    }

                                    // check
                                    input[type=checkbox]:checked {
                                        ~ label.acceptance-label {
                                            &:before {
                                                border: 1px solid $button-bg;
                                                background-color: $button-bg;
                                            }

                                            &:after {
                                                background: url(/img/icon_check.svg) no-repeat center center;
                                                background-size: 100% auto;
                                            }
                                        }
                                    }
                                }
                            }

                            & + br {
                                display: none;
                            }
                        }

                        // 送信
                        .wpcf7-submit {
                            display: inline-block;
                            vertical-align: bottom;

                            cursor: pointer;
                            background: $button-bg;
                            border: none;
                            border-radius: 5px;
                            color: white;

                            padding: .5rem 0;
                            width: 200px;

                            @include transition(background-color, .15s);

                            &:not(:disabled).on {
                                background: $button-bg-hover;
                            }

                            &:disabled {
                                cursor: not-allowed;
                                background: #ddd;
                                color: lighten($base-font-color, 40%);
                            }
                        }

                        // ajax-loader
                        .ajax-loader {
                            background: none;

                            border: 5px solid #f3f3f3;
                            border-top: 5px solid $button-bg;
                            border-radius: 50%;

                            margin: 4px 0 0 20px;
                            width: 36px;
                            height: 36px;
                            vertical-align: top;

                            animation: spin 1s linear infinite;
                        }
                    }


                    // メッセージ
                    .wpcf7-response-output {
                        margin: 30px 0 0 0;
                        border: 1px solid $form-border-color-focus;
                        border-radius: 5px;
                        padding: 15px 25px;
                    }

                    &.invalid {
                        .wpcf7-response-output {
                            border-color: rgba($error-color, .5);
                            color: $error-color;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}