@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


// NEWS & INFO. - 詳細
// BLOG - 詳細
.content-wrapper {
    .container {
        div.content.page.post-detail {
            // 全投稿がヒットしてしまうため非表示
            .nav-single {
                display: none;
            }

            article {
                margin-top: -6px;

                .entry-header {
                    padding: 0;
                    margin: 0 0 35px 0;

                    .entry-title {
                        color: $base-font-color;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 1.75;
                        margin: 0 0 10px 0;
                    }

                    .entry-meta {
                        position: static;
                        text-align: left;
                        margin: 0;

                        time {
                            color: $sub-text-color;
                            font-size: 14px;
                            font-weight: 200;
                            line-height: 1;
                        }
                    }
                }

                .entry-content {
                    p {
                        margin-bottom: 25px;

                        a {
                            color: $content-link-color;
                            @include transition(color, .15s);

                            &.on {
                                color: $content-link-color-hover;
                            }
                        }
                    }
                }
            }

            // コメント
            #comments {
                // コメント
                #comments-title {
                    color: $base-font-color;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.75;
                    // text-transform: none;
                    padding-bottom: 25px;
                }

                .commentlist {
                    & > li.comment {
                        background: white;
                        border: 1px solid #e4e4e4;
                        border-radius: 5px;
                        padding: 25px;
                        margin: 0 0 25px 0;

                        // 吹き出し
                        &:before {
                            position: absolute;
                            display: block;
                            content: "";

                            background: white url(/img/comment-arrow.svg) no-repeat 0 0 / 21px auto;
                            width: 21px;
                            height: 18px;
                            left: -20px;
                            top: 25px;
                            overflow: hidden;
                        }
                    }

                    // アイコン
                    .avatar {
                        box-shadow: none;
                        width: 68px;
                        height: 68px;
                        left: -100px;
                        top: 0;
                    }

                    article.comment {
                        margin: 0;

                        .comment-meta {
                            color: $base-font-color;
                            line-height: 1.5;
                            margin-bottom: 25px;
                            width: 100%;

                            .comment-author {
                                font-size: 12px;

                                // time
                                & > a {
                                    pointer-events: none;
                                }

                                .fn {
                                    margin-right: .5em;

                                    a {
                                        color: $content-link-color;

                                        @include transition(color, .15s);

                                        &:hover {
                                            color: $content-link-color-hover;
                                        }
                                    }
                                }

                                a {
                                    // pointer-events: none;
                                    font-weight: 200;

                                    time {
                                        color: $base-font-color;
                                    }
                                }
                            }
                        }

                        .comment-content {
                            p {
                                margin-bottom: 25px;
                            }
                        }

                        // 返信ボタン
                        .reply {
                            line-height: 1;

                            a.comment-reply-link {
                                position: relative;
                                background: none;
                                color: $link-text-color;
                                padding: 0 0 0 17px;

                                @include transition(color, .15s);

                                span {
                                    display: none;
                                }

                                &:before {
                                    position: absolute;
                                    display: block;
                                    content: "";

                                    background-color: darken($bullet-color, 10%);
                                    -webkit-mask: url(/img/button_arrow_reply.svg) no-repeat 0 0 / 12px auto;
                                    mask: url(/img/button_arrow_reply.svg) no-repeat 0 0 / 12px auto;

                                    width: 12px;
                                    height: 12px;
                                    left: 0;
                                    top: 0;

                                    @include transition(background-color, .15s);
                                }

                                &:hover {
                                    color: $link-text-color-hover;

                                    &:before {
                                        background-color: $button-bg;
                                    }
                                }
                            }
                        }

                        // 返信フォーム
                        & + .comment-respond {
                            margin-top: 25px;
                        }
                    }

                    .edit-link {
                        a.comment-edit-link {
                            display: block;
                            font-size: 11px;
                            font-weight: 200;
                            padding: 4px 8px;
                            right: 10px;
                            top: 10px;

                            &:hover {
                                background: $signature-color;
                            }
                        }
                    }


                    // スレッド
                    .children {
                        li.comment {
                            background: $base-bg-color;
                            border: none;
                            margin: 25px 0 0 0;

                            // コメント
                            .comment-respond {
                                border: 2px solid white;
                            }
                        }

                        // アイコン
                        .avatar {
                            border: none;
                            left: 25px;
                            top: 25px;
                            width: 39px;
                            height: 39px;
                        }
                    }
                }


                // コメントフォーム
                .comment-respond {
                    background: $base-bg-color;
                    border: none;
                    border-radius: 0;
                    padding: 20px 25px 25px 25px;
                    margin: 0;
                    width: 100%;

                    // コメントをキャンセル
                    #cancel-comment-reply-link {
                        position: absolute;
                        color: $link-text-color;
                        font-size: 12px;
                        font-weight: 300;
                        line-height: 1;
                        padding-left: 18px;

                        right: 25px;
                        top: 25px;

                        @include transition(color, .15s);

                        &:before {
                            position: absolute;
                            display: block;
                            content: "";

                            background-color: darken($bullet-color, 10%);
                            -webkit-mask: url(/img/button_comment_cancel.svg) no-repeat 0 0 / 12px auto;
                            mask: url(/img/button_comment_cancel.svg) no-repeat 0 0 / 12px auto;

                            width: 12px;
                            height: 12px;
                            left: 0;
                            top: 0;

                            @include transition(background-color, .15s);
                        }

                        &:hover {
                            background-position: 0 -12px;
                            color: $link-text-color-hover;
                            text-decoration: none;

                            &:before {
                                background-color: $button-bg;
                            }
                        }
                    }

                    label {
                        position: static;
                        display: block;
                        background: none;
                        box-shadow: none;
                        border: none;
                        font-size: 14px;
                        line-height: 1;
                        padding: 0;
                        margin: 0;

                        .required {
                            position: static;
                            color: $error-color;
                            font-size: 14px;
                            font-weight: normal;
                        }
                    }

                    input[type=text],
                    input[type=email],
                    textarea {
                        position: static;
                        border: 1px solid $form-border-color;
                        border-radius: 0;
                        box-shadow: none;
                        text-indent: 0;
                        padding: .375rem .7rem;
                        margin: 8px 0 0 0;

                        @include transition(border-color, .15s);

                        &:focus {
                            border-color: $form-border-color-focus;
                        }
                    }

                    input[type=text],
                    input[type=email] {
                        height: auto !important;
                    }


                    h3.comment-reply-title {
                        color: $base-font-color;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 1.75;
                        margin: 0 0 10px 0;
                    }

                    p.comment-notes {
                        font-size: 14px;
                        margin: 0;

                        .required {
                            color: $error-color;
                        }
                    }

                    p.logged-in-as {
                        a {
                            color: $content-link-color;
                            @include transition(color, .15s);

                            &:hover {
                                color: $content-link-color-hover;
                                text-decoration: none;
                            }
                        }
                    }

                    // コメント
                    p.comment-form-comment {
                        margin: 25px 0 0 0;

                        textarea {
                            vertical-align: bottom;
                        }
                    }

                    p.comment-form-author,
                    p.comment-form-email,
                    p.comment-form-url {
                        margin: 25px 0 0 0;
                    }


                    // コメントを送信
                    p.form-submit {
                        float: none;
                        padding: 0;
                        margin: 25px 0 0 0;

                        input#submit {
                            position: static;
                            left: initial;
                            box-shadow: none;
                            text-shadow: none;

                            cursor: pointer;
                            background: $button-bg;
                            border-radius: 5px;
                            color: white;
                            font-size: 1rem;

                            padding: .5rem 0;
                            margin: 0;
                            width: 200px;

                            @include transition(all, .15s);

                            &.on {
                                background-color: $button-bg-hover;
                            }
                        }
                    }
                }
            }
        }
    }
}
