@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


// PROFILE
.content-wrapper {
    .container {
        div.content.page.profile {
            article {
                .entry-content {
                    // 不要な要素非表示
                    //& > br {
                    //    display: none;
                    //}

                    p {
                        margin: 0;

                        //&:first-child {
                        //    display: none;
                        //}
                    }


                    // ポートレート
                    .portrait-wrapper {
                        float: right;
                        margin: 3px 0 25px 50px;

                        .portrait {
                            background: url(/img/photo_komori_profile.png) no-repeat -18px -8px;
                            background-size: 290px auto;
                            width: 250px;
                            height: 180px;
                        }

                        p.caption {
                            font-size: 11px;
                            line-height: 1;
                            margin: 12px 0 0 0;
                        }
                    }

                    // 小森 俊明
                    h2.name {
                        float: left;

                        border-left: 8px solid $regular-border-color;
                        color: $base-font-color;
                        font-size: 24px;
                        line-height: 1.25;
                        padding-left: 15px;
                        margin: 0 0 35px 0;
                    }

                    .clear {
                        clear: left;
                    }

                    // 本文
                    p + p,
                    ul + p {
                        margin-top: 35px;
                    }

                    p.history {
                        margin-top: 1rem;
                    }

                    p.project {
                        margin-top: 10px;
                        margin-left: 2rem;
                        text-indent: -1.25rem;
                    }

                    ul.belong {
                        margin-top: 8px;
                        margin-left: 1rem;
                        padding: 0;
                        list-style: none;
                    }
                }
            }


            // Sharedaddy
            .sharing {
                margin-bottom: 0;
            }

            footer.entry-meta {
                margin-top: 0;
            }
        }
    }
}