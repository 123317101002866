@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


// LESSON
.content-wrapper {
    .container {
        div.content.page.lesson {
            article {
                .entry-content {
                    & > p {
                        display: none;
                    }
                }

                .block-lesson {
                    & > br {
                        display: none;
                    }

                    h2.entry-title {
                        border-left: 8px solid $regular-border-color;
                        color: $signature-color;
                        font-size: 24px;
                        line-height: 1.25;
                        padding-left: 15px;
                        margin: 0 0 35px 0;

                        //.rate {
                        //    font-size: 18px;
                        //    margin-left: 5px;
                        //}
                    }

                    p {
                        line-height: 1.75;
                        margin-bottom: 25px;
                    }

                    dl {
                        margin: 0;
                        padding: 0;

                        dt.bullet {
                            position: relative;
                            display: block;
                            list-style: none;

                            font-size: 20px;
                            line-height: 1.25;

                            padding-left: 24px;
                            margin: 45px 0 20px 0;

                            &:before {
                                position: absolute;
                                display: block;
                                content: "";

                                background-color: $regular-border-color;
                                border-radius: 50%;
                                width: 18px;
                                height: 18px;
                                left: -1px;
                                top: 5px;
                            }

                            .rate {
                                font-size: 16px;
                                margin-left: 5px;
                            }
                        }

                        dd {
                            margin-left: 25px;
                        }
                    }
                }


                // Sharedaddy
                .sharing {
                    margin-bottom: 0;
                }

                footer.entry-meta {
                    margin-top: 0;
                }
            }
        }
    }
}