@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


/* Structure */
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}


// Sound Player
.player-wrapper {
    position: absolute;
    width: 100%;
    min-width: 1050px;
    min-height: 50px;
    left: 0;
    top: 0;
    z-index: 10;
    overflow: hidden;
}


// Flash
.flash {
    //display: none;

    position: absolute;
    width: 100%;
    min-width: 1050px;
    min-height: 50px;
    left: 0;
    top: 0;
    z-index: 10;
    overflow: hidden;

    .info {
        font-size: 11px;
        text-align: left;
        margin: 10px 0 0 15px;

        a {
            color: $sub-text-color;
            word-break: normal;
        }
    }
}


.content-wrapper {
    flex: 1;
    margin: 0 auto;
    min-width: 1050px;

    // Header
    header.site-header {
        text-align: left;
        margin: 0 auto;
        padding: 0;
        width: 1000px;

        h1.site-title {
            img {
                vertical-align: bottom;
            }
        }

        span.decoration {
            position: absolute;
            display: block;
            background-color: $bullet-color;
            left: 0;
            top: 0;
        }

        // グリッドチェック用
        span:not(.decoration) {
            position: absolute;
            display: block;
            background-color: $bullet-color;
            width: 1px;
            height: 1000px;
            overflow: hidden;

            &.check-border-1 {
                left: 0;
                top: 0;
            }

            &.check-border-2 {
                left: 157px;
                top: 0;
            }
        }

        // HOME
        &.home {
            margin: 0 auto;
            padding: 70px 0;

            h1.site-title {
                width: 569px;
            }

            span.decoration {
                width: 157px;
                height: 18px;
            }
        }

        // Page
        &.page {
            margin: 0 auto;
            padding: 60px 0 109px 0;

            h1.site-title {
                width: 475px;
            }

            span.decoration {
                width: 157px;
                height: 15px;
            }
        }
    }

    // Main menu / Contents
    .container {
        margin: 0 auto;
        min-height: 465px;
        width: 1000px;

        // Main menu
        .sidebar {
            float: left;
            text-align: left;
            margin: 0 0 60px -2px;
            width: 170px;

            nav {
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    li {
                        display: block;
                        width: 170px;
                        height: 20px;

                        & + li {
                            margin-top: 35px;
                        }

                        a {
                            display: block;

                            background-color: $link-text-color;
                            -webkit-mask: url(/img/menu_main.svg) no-repeat 0 0 / 400px 400px;
                            mask: url(/img/menu_main.svg) no-repeat 0 0 / 400px 400px;

                            text-indent: -9999px;
                            width: 100%;
                            height: 100%;

                            @include transition(background-color, .15s);

                            &.home {
                                -webkit-mask-position: 0 0;
                                mask-position: 0 0;
                            }

                            &.news {
                                -webkit-mask-position: 0 -50px;
                                mask-position: 0 -50px;
                            }

                            &.profile {
                                -webkit-mask-position: 0 -100px;
                                mask-position: 0 -100px;
                            }

                            &.works {
                                -webkit-mask-position: 0 -150px;
                                mask-position: 0 -150px;
                            }

                            &.archives {
                                -webkit-mask-position: 0 -200px;
                                mask-position: 0 -200px;
                            }

                            &.lesson {
                                -webkit-mask-position: 0 -250px;
                                mask-position: 0 -250px;
                            }

                            &.blog {
                                -webkit-mask-position: 0 -300px;
                                mask-position: 0 -300px;
                            }

                            &.contact {
                                -webkit-mask-position: 0 -350px;
                                mask-position: 0 -350px;
                            }

                            &.selected, &.on {
                                background-color: $link-text-color-hover;
                            }
                        }
                    }
                }
            }
        }

        // content
        div.content {
            float: right;
            text-align: left;
            width: 772px;

            // お問い合わせ
            section.contact {
                background-color: $base-bg-color;
                text-align: center;
                padding: 32px 35px 45px 35px;

                p.catch {
                    color: $title-color;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 1.25;
                    margin-bottom: 30px;
                }

                a.button {
                    display: block;
                    background-color: $button-bg;

                    border-top: 5px solid $button-bg;
                    border-bottom: 5px solid $button-bg-hover;
                    border-radius: 10px;

                    color: white;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 1;

                    margin: 0 auto;
                    padding-top: 20px;

                    width: 380px;
                    height: 80px;

                    @include transition(all, .15s);

                    &.on {
                        background-color: $button-bg-hover;
                        border-top: 3px solid darken($button-bg-hover, 5%);
                        border-bottom: 0 solid $button-bg-hover;
                        padding-top: 23px;
                    }
                }

                //& + br {
                //    display: none;
                //}
            }

            p + section.contact,
            dl + section.contact {
                margin-top: 45px;
            }


            // PAGE TOP
            nav.page-top {
                margin: 45px 0;
                // margin: 50px 0;

                a.page-top {
                    position: relative;
                    display: block;

                    margin: 0 0 0 auto;

                    width: 105px;
                    height: 16px;
                    overflow: hidden;

                    span {
                        position: absolute;
                        text-indent: -9999px;
                        overflow: inherit;
                        @include transition(background-color, .15s);

                        &.arrow {
                            background-color: $bullet-color;
                            -webkit-mask: url(/img/button_page-top.svg) no-repeat 0 0 / 104px 16px;
                            mask: url(/img/button_page-top.svg) no-repeat 0 0 / 104px 16px;

                            width: 16px;
                            height: 16px;
                            left: 0;
                            top: 0;
                        }

                        &.text {
                            background-color: $link-text-color;
                            -webkit-mask: url(/img/button_page-top.svg) no-repeat -25px 0 / 104px 16px;
                            mask: url(/img/button_page-top.svg) no-repeat -25px 0 / 104px 16px;

                            width: 80px;
                            height: 16px;
                            left: 25px;
                            top: 0;
                        }
                    }
                }

                // ページの途中
                &.middle {
                    border-bottom: 1px dotted $regular-border-color;
                    padding-bottom: 45px;
                }


                // 複合型
                // BACK
                &.back {
                    display: flex;
                    align-items: center;

                    a.back {
                        position: relative;
                        display: block;
                        width: 70px;
                        height: 16px;
                        overflow: hidden;

                        span {
                            position: absolute;
                            text-indent: -9999px;
                            overflow: inherit;
                            @include transition(background-color, .15s);

                            &.arrow {
                                background-color: $bullet-color;
                                -webkit-mask: url(/img/button_back.svg) no-repeat 0 0 / 70px 16px;
                                mask: url(/img/button_back.svg) no-repeat 0 0 / 70px 16px;

                                width: 16px;
                                height: 16px;
                                left: 0;
                                top: 0;
                            }

                            &.text {
                                background-color: $link-text-color;
                                -webkit-mask: url(/img/button_back.svg) no-repeat -25px 0 / 70px 16px;
                                mask: url(/img/button_back.svg) no-repeat -25px 0 / 70px 16px;

                                width: 45px;
                                height: 16px;
                                left: 25px;
                                top: 0;
                            }
                        }
                    }
                }


                // on mouse
                a.back,
                a.page-top {
                    &.on {
                        span {
                            &.arrow {
                                background-color: $button-bg;
                            }

                            &.text {
                                background-color: $link-text-color-hover;
                            }
                        }
                    }
                }
            }
        }
    }
}


// Footer
.footer-wrapper {
    border-top: 1px dotted $regular-border-color;

    footer {
        min-width: 1050px;
        margin: 45px auto 50px auto;

        p {
            &.copyright {
                color: $sub-text-color;
                font-size: 12px;
                line-height: 1;
                margin: 0 25px;
            }
        }
    }
}