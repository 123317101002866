@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


// ARCHIVES - index
.content-wrapper {
    .container {
        div.content.page.archives.index {
            margin-top: -3px;

            nav {
                ul {
                    margin: 0 0 50px 32px;
                    padding: 0;
                    list-style: none;

                    li {
                        display: block;

                        a {
                            display: block;
                            color: $sub-text-color;
                            font-size: 22px;
                            font-weight: 500;
                            line-height: 1;
                            letter-spacing: .01em;

                            @include transition(color, .15s);

                            &:hover {
                                color: $link-text-color-hover;
                            }
                        }

                        & + li {
                            margin-top: 51px;
                        }
                    }
                }
            }
        }
    }
}


// ARCHIVES - detail
.content-wrapper {
    .container {
        div.content.page.archives {
            .entry-header {
                padding: 0;

                h2.entry-title {
                    border-left: 8px solid $regular-border-color;
                    color: $signature-color;
                    font-size: 24px;
                    line-height: 1.25;
                    padding-left: 15px;
                    margin: 0 0 35px 0;
                }
            }

            .entry-content {
                // 年表記, カテゴリ
                h3.category {
                    color: $sub-text-color;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1.25;
                    letter-spacing: 0;
                    margin: 35px 0 25px 0;
                }

                .title-archive {
                    color: $base-font-color;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1.75;
                    text-transform: initial;
                    letter-spacing: 0;
                    margin-bottom: .25rem;
                }

                p {
                    // 外部リンク
                    a {
                        display: inline-block;
                        color: $content-link-color;
                        margin-top: .25rem;

                        @include transition(all, .15s);

                        &.on {
                            color: $content-link-color-hover;
                            // text-decoration: underline;
                        }
                    }
                }

                p + h3.category {
                    border-top: 1px dotted $regular-border-color;
                    margin-top: 35px;
                    padding-top: 30px;
                }

                p + h4 {
                    margin-top: 35px;
                }
            }
        }
    }
}