@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


// Base
* {
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

body, input, textarea {
    color: $text-color;
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.75;
    letter-spacing: 0.1px;
}

.strong {
    font-weight: 500;
}


//#flashContent {
//    display: none;
//}

input, textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}



// clearfix
.clear {
    clear: both;
}

.clearfix::after {
    content: "";
    display: block;
    clear: both;
}