@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


// 404
.content-wrapper {
    .container {
        div.content.page.not-found {
            .entry-header {
                padding: 0;

                h2.entry-title {
                    border-left: 8px solid $regular-border-color;
                    color: $signature-color;
                    font-size: 24px;
                    line-height: 1.25;
                    padding-left: 15px;
                    margin: 0 0 35px 0;
                }
            }

            .entry-content {
                p.description {
                    font-size: 1rem;
                    line-height: 1.75;
                    margin-bottom: 20px;
                }

                // 検索フォーム
                input#s {
                    background: url(/img/icon_search.svg) no-repeat 10px 10px / 20px auto;
                    border: 1px solid $regular-border-color;
                    box-shadow: none;
                    font-size: 1rem;
                    line-height: 1.75;
                    padding: .375rem .7rem .375rem 38px;
                    width: 50%;
                    height: auto;
                }

                .widget {
                    margin-top: 50px;

                    h2.widgettitle {
                        color: $base-font-color;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 1.25;
                        letter-spacing: 0;
                        margin-bottom: 10px;
                    }

                    ul {
                        padding: 0 0 0 1.75rem;
                        list-style-type: disc;

                        li {
                            font-size: 1rem;
                            line-height: 1.75;

                            a {
                                color: $base-font-color;
                                font-weight: 200;

                                @include transition(color, .15s);

                                &:hover {
                                    color: $signature-color;
                                }
                            }
                        }
                    }


                    // アーカイブ（目的が違うので表示しない）
                    &.widget_archive {
                        display: none;
                    }
                }
            }
        }
    }
}