@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


// Sound Player
.player-wrapper.page {
    height: 60px;
    top: 132px;
    z-index: -1;
}


// Pages
.content-wrapper {
    .container {
        // Content
        div.content.page {
            margin-top: -6px;

            .entry-meta {
                text-align: right;
                margin-top: 15px;
                width: initial;
            }

            .hentry,
            .no-results {
                border: none;
                margin: 0;
                padding: 0;

                // 編集
                .edit-link {
                    display: inline-block;

                    a {
                        position: static;
                        display: block;
                        color: $sub-text-color;
                        font-size: 11px;
                        font-weight: 200;
                        padding: 4px 8px;

                        &:hover {
                            background-color: $button-bg-hover;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}