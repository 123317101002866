
// Variables
$base-font-color: #363636;
$base-bg-color: #f4f4f4;

// Base Color
$signature-color: #2db9bd;

$bullet-color: #ddd;
$regular-border-color: #ddd;


// Text
$title-color: #2db9bd;

$text-color: #363636;
$sub-text-color: #888;

$link-text-color: $sub-text-color;
$link-text-color-hover: #2db9bd;

$error-color: #f3445e;

$datetime-color: #aaa;


// Link
$content-link-color: #43d1d1;
$content-link-color-hover: #2db9bd;


// お問い合わせ
$button-bg: #43d1d1;
$button-bg-hover: #2db9bd;
$button-bg-disabled: #ddd;

$form-border-color: $regular-border-color;
$form-border-color-focus: $button-bg;