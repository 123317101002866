@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


// WORKS
.content-wrapper {
    .container {
        div.content.page.works {

            // 推薦文
            section.testimonial-wrapper {
                background: $base-bg-color url(/img/icon_testimonial.svg) no-repeat 21px 19px / 34px auto;

                padding: 38px 35px 25px 53px;
                margin-bottom: 50px;

                p.testimonial {
                    color: #2c2c2c;
                    font-style: italic;
                    font-weight: 400;
                    line-height: 2.25;
                    margin: 0 0 4px 0;
                }

                p.signature {
                    font-size: 14px;
                    text-align: right;
                    margin: 0;

                    .dash {
                        color: $sub-text-color;
                    }
                }
            }

            // 作品
            article {
                .entry-content {
                    & > br {
                        display: none;
                    }

                    h5.title-work {
                        font-size: 18px;
                        font-weight: 500;
                        margin-bottom: .25rem;
                    }

                    p.description-work {
                        line-height: 2;
                        margin: 0;
                    }

                    p.description-work + br + h5.title-work {
                        margin-top: 35px;
                    }
                }
            }
        }
    }
}