@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


// Sharedaddy
.sharing {
    padding: 0 !important;
    margin: 50px 0;

    ul {
        li {
            font-size: 0;
            padding: 0 !important;

            &:last-child {
                margin-right: 0 !important;
            }

            // custom
            &.share-custom {
                margin: 0 !important;

                a.sharing-anchor {
                    background: url(/img/icon_share_more.svg) no-repeat 7px center / 10px auto;
                    border: 1px solid #d8d8d8 !important;
                    border-radius: 3px;

                    color: #666;
                    font-family: Arial, Tahoma, Verdana, sans-serif;
                    font-size: 11px;
                    line-height: 18px;

                    padding: 0 8px 0 23px;
                    @include transition(all, .15s);

                    &:hover {
                        background-color: #eee;
                        color: $link-text-color-hover;
                    }
                }
            }
        }
    }

    // print, StumbleUpon etc.
    .sharing-hidden {
        .inner {
            // display: block !important;

            border: 1px solid $regular-border-color;
            padding: 6px 0;
            width: auto;

            ul {
                background: none !important;

                li {
                    float: none;

                    font-family: Arial, Tahoma, Verdana, sans-serif;
                    padding: 4px 0 4px 10px !important;
                    margin: 0 !important;
                    width: 110px;

                    &:first-child,
                    &:nth-child(2) {
                        margin: 0 !important;
                    }

                    &.share-end {
                        padding: 0 !important;
                    }

                    a {
                        padding: 0 0 0 23px;

                        &:hover {
                            color: $link-text-color-hover;
                        }
                    }
                }
            }
        }
    }
}