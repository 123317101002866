@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


// Plugin関連

// WP Page Navi
.wp-pagenavi {
    text-align: center;
    margin-top: 50px;

    span.pages {
        display: none;

        border: none;
        padding: 0 !important;
        margin: 0 20px 0 0 !important;
    }

    // ページ
    span.current {
        background-color: $button-bg;
        border: none;
        color: white;

        font-weight: 500 !important;
        line-height: 36px;

        padding: 0 !important;
        margin-right: 8px !important;

        min-width: 36px;
    }

    a {
        -webkit-transition: background-color .3s, border-color .3s, color .15s;
        -moz-transition: background-color .3s, border-color .3s, color .15s;
        -o-transition: background-color .3s, border-color .3s, color .15s;
        transition: background-color .3s, border-color .3s, color .15s;

        &:hover {
            border-color: $regular-border-color !important;
        }

        &.on {
            //background-color: #f4f4f4 !important;
            //border-color: rgba($signature-color, .5) !important;
            background-color: $button-bg !important;
            border-color: $button-bg !important;
            color: white;
        }
    }

    a.page,
    a.previouspostslink,
    a.nextpostslink {
        border: 1px solid $regular-border-color;
        line-height: 34px;
        padding: 0 !important;
        margin-right: 8px !important;
        min-width: 36px;
    }

    a.previouspostslink {
        background: url(/img/nav_pagenavi.svg) no-repeat 0 1px / auto 68px;
        text-indent: -9999px;

        &.on {
            background-position: 0 -33px;
        }
    }

    a.nextpostslink {
        background: url(/img/nav_pagenavi.svg) no-repeat -34px 1px / auto 68px;
        text-indent: -9999px;

        &.on {
            background-position: -34px -33px;
        }
    }

    // 先頭
    a.first {
        background: url(/img/nav_pagenavi.svg) no-repeat -68px 1px / auto 68px;
        border: 1px solid $regular-border-color;
        text-indent: -9999px;
        margin: 8px !important;
        width: 36px;

        &.on {
            background-position: -68px -33px;
        }
    }

    // 最後
    a.last {
        background: url(/img/nav_pagenavi.svg) no-repeat -102px 1px / auto 68px;
        border: 1px solid $regular-border-color;
        text-indent: -9999px;
        margin: 0 !important;
        width: 36px;

        &.on {
            background-position: -102px -33px;
        }
    }

    // ...
    span.extend {
        border: none;
        background: url(/img/nav_extend.svg) no-repeat center 1px / 32px auto;
        margin: 0 5px 0 -3px !important;
        text-indent: -9999px;
    }
}




// GDPR Cookie Consent
#cookie-law-info-bar {
    box-shadow: none !important;
    border-top: 1px dotted $regular-border-color;
    padding: 10px 20px !important;
    opacity: 1 !important;
    z-index: 1500 !important;

    .cli-style-v2 {
        .cli-bar-message {
            font-size: 12px;
            font-weight: 200;
            line-height: 1.5;
        }

        .cli-bar-btn_container {
            // Cookie settings
            a.cli_settings_button {
                color: $link-text-color !important;
                font-size: 12px;
                font-weight: 200;
                text-decoration: none;
                margin: 0 15px 0 5px !important;

                @include transition(color, .15s);

                &:hover {
                    color: $link-text-color-hover !important;
                }
            }

            // Accept
            a.cookie_action_close_header {
                background-color: $button-bg !important;
                font-size: 12px;
                font-weight: 200;
                padding: 10px 15px;

                @include transition(background-color, .15s);

                &:hover {
                    background-color: $button-bg-hover !important;
                }
            }
        }
    }
}

// GDPR Cookie Consent - modal
#cliSettingsPopup {
    z-index: 2100 !important;

    .cli-modal-dialog {
        .cli-modal-content {
            // close
            .cli-modal-close {
                cursor: pointer;
                opacity: .2;
                @include transition(opacity, .15s);

                &:hover {
                    opacity: .3;
                }
            }

            // modal
            .cli-tab-container {
                h4 {
                    color: $signature-color;
                    font-weight: 500;
                    line-height: 1;
                    margin: 0 0 15px 0;
                }

                .cli-privacy-content-text {
                    font-size: 12px;
                    font-weight: 200;
                    line-height: 1.75;
                }

                a.cli-privacy-readmore {
                    color: $link-text-color;
                    font-size: 12px;
                    font-weight: 200;
                    text-decoration: none;

                    @include transition(color, .15s);

                    &:hover {
                        color: $link-text-color-hover;
                    }
                }

                .cli-tab-section {
                    margin: 5px 0 0 0;

                    .cli-tab-header {
                        background-color: $base-bg-color;

                        a.cli-nav-link {
                            color: $base-font-color;
                            font-size: 14px;
                            font-weight: 200;

                            &:before {
                                border-right-color: darken($bullet-color, 10%);
                                border-bottom-color: darken($bullet-color, 10%);
                            }
                        }

                        // Always Enabled
                        span.cli-necessary-caption {
                            color: $base-font-color;
                            font-size: 12px;
                            font-weight: 200;
                        }

                        .cli-switch {
                            input:checked + .cli-slider {
                                background-color: $button-bg;
                            }

                            .cli-slider {
                                background-color: #ccc;

                                &:after {
                                    color: $base-font-color;
                                    font-size: 12px;
                                    font-weight: 200;
                                }
                            }
                        }
                    }

                    p {
                        font-size: 12px;
                        font-weight: 200;
                        line-height: 1.75;
                    }

                    & + .cli-tab-section {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

.cli-settings-overlay {
    transform: none !important;
    z-index: 2000 !important;
    width: 0;
    height: 0;
    transition: opacity .5s linear !important;

    &.show {
        width: 100%;
        height: 100%;
    }
}
