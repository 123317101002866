@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


// NEWS & INFO. - 一覧
// BLOG - 一覧
.content-wrapper {
    .container {
        div.content.page.posts {
            .entry-content {
                margin: -3px 0 -30px 0;

                // 各エントリ
                .hentry {
                    position: relative;

                    .entry-header {
                        position: relative;
                        padding: 0;

                        .entry-title {
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 1.75;
                            padding: 0 65px 0 0;
                            margin: 0 0 10px 0;

                            a {
                                word-break: normal;
                                color: $link-text-color;
                                @include transition(color, .15s);

                                &:hover {
                                    color: $link-text-color-hover;
                                }
                            }
                        }

                        .entry-meta {
                            text-align: left;
                            padding: 0 0 35px 0;
                            margin: 0;

                            time {
                                color: $datetime-color;
                                font-size: 14px;
                                font-weight: normal;
                                line-height: 1;
                            }
                        }

                        .comments-link {
                            position: absolute;
                            width: 40px;
                            height: 34px;
                            right: 0;
                            top: 2px;

                            a {
                                position: absolute;
                                display: block;
                                background: none;

                                font-size: 12px;
                                line-height: 31px;

                                width: 100%;
                                height: 100%;
                                left: 0;
                                top: 0;
                                overflow: hidden;
                                z-index: 30;

                                @include transition(color, .15s);

                                &:hover {
                                    color: $base-font-color;
                                }
                            }

                            span {
                                position: absolute;
                                display: block;
                                width: 100%;
                                height: 100%;
                                left: 0;
                                top: 0;
                                overflow: hidden;

                                @include transition(background-color, .3s);

                                &.balloon-border {
                                    background: darken($regular-border-color, 4%);
                                    -webkit-mask: url(/img/comment_balloon_border.svg) no-repeat 0 0 / 100% 100%;
                                    mask: url(/img/comment_balloon_border.svg) no-repeat 0 0 / 100% 100%;
                                    z-index: 20;
                                }

                                &.balloon-bg {
                                    background: lighten($regular-border-color, 8%);
                                    -webkit-mask: url(/img/comment_balloon_bg.svg) no-repeat 0 0 / 100% 100%;
                                    mask: url(/img/comment_balloon_bg.svg) no-repeat 0 0 / 100% 100%;
                                    z-index: 10;
                                }
                            }

                            &.on {
                                a {
                                    color: white;
                                }

                                span {
                                    &.balloon-border {
                                        background-color: $signature-color;
                                    }

                                    &.balloon-bg {
                                        background-color: lighten($button-bg, 5%);
                                    }
                                }
                            }
                        }
                    }

                    .entry-content {
                        display: none;
                    }

                    footer.entry-meta {
                        position: absolute;
                        right: 0;
                        bottom: 10px;
                    }
                }

                .hentry + .hentry {
                    border-top: 1px dotted $regular-border-color;
                    padding-top: 30px;
                }
            }
        }
    }
}