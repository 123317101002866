@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


// 印刷用簡易設定
@media print {
    .flash,
    .player-wrapper {
        display: none;
    }

    .content-wrapper {
        header.site-header {
            padding: 60px 0 70px !important;

            span.decoration {
                display: none;
            }
        }

        .container {
            div.content {
                width: 100%;

                // 全投稿がヒットしてしまうため非表示
                .nav-single {
                    display: none !important;
                }

                // お問い合わせ
                section.contact {
                    padding: 0;
                    height: 0;

                    * {
                        display: none !important;
                    }
                }
            }
        }
    }

    nav {
        display: none !important;
    }
}