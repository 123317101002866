@charset "UTF-8";
@import "../config/variables";
@import "../config/mixins";


// 調整用
.content-wrapper {
    .container {
        .line {
            display: none;

            position: absolute;
            background-color: #ddd;
            overflow: hidden;

            &.line-1 {
                width: 1px;
                height: 100%;
                left: calc(50% - 272px);
                top: 0;
            }

            &.line-2 {
                width: 100%;
                height: 1px;
                left: 0;
                top: 751px;
            }
        }
    }
}


// Sound Player
.player-wrapper.home {
    height: 500px;
    top: 423px;

    canvas {
        opacity: 1;
        transition: opacity .3s ease-in-out;

        &.hide {
            opacity: 0;
        }
    }

    .control-wrapper {
        position: absolute;
        width: 270px;
        left: calc(50% - 281px);
        top: 309px;
        z-index: 10;

        display: flex;
        align-items: center;

        opacity: 1;
        transition: opacity .3s ease-in-out;

        &.hide {
            pointer-events: none;
            opacity: 0;
        }

        button {
            background: none;
            border: none;
            outline: none;
            appearance: none;

            cursor: pointer;
            background: #969696;
            mask: url(/img/buttons_player.svg) no-repeat 0 0 / 48px auto;
            text-indent: -9999px;
            margin: 0;
            padding: 0;

            width: 24px;
            height: 24px;
            overflow: hidden;
            transition: background-color .15s linear;

            &.on {
                background-color: $signature-color;
            }

            &.play-button {
                &.stop {
                    mask-position: 0 -24px;
                }
            }

            &.skip-button {
                mask-position: -24px 0;
                margin-left: 3px;
            }
        }

        .title {
            color: #888;
            font-size: 11px;
            line-height: 1;
            margin: 0 0 0 13px;

            span.year {
                margin-left: 5px;
            }
        }
    }

    .loader-wrapper {
        pointer-events: none;

        position: absolute;
        width: 300px;
        height: 50px;
        left: calc(50% - 272px);
        top: 297px;
        z-index: 20;

        opacity: 0;
        transition: opacity .3s ease-in-out;

        &.show {
            opacity: 1;
        }

        // Loader
        .loader,
        .loader:before,
        .loader:after {
            background: rgba($signature-color, .7);
            animation: loading 1.2s infinite ease-in-out;
            width: 3px;
            height: 20px;
        }

        .loader {
            position: relative;
            color: rgba($signature-color, .7);
            left: 8px;
            top: 15px;
            transform: translateZ(0);
            animation-delay: -.16s;
        }

        .loader:before,
        .loader:after {
            content: "";
            position: absolute;
            display: block;
            color: rgba($signature-color, .7);
            top: 0;
        }

        .loader:before {
            left: -8px;
            animation-delay: -.32s;
        }

        .loader:after {
            left: 8px;
        }

        // progress
        .progress {
            position: absolute;
            left: 35px;
            top: calc((100% - 11px) / 2);

            color: #999;
            font-size: 11px;
            line-height: 1;
            margin: 0;
        }
    }
}



@keyframes loading {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 20px;
    }
    40% {
        box-shadow: 0 -7px;
        height: 27px;
    }
}




// HOME
.content-wrapper {
    .container {
        // HOME用sidebar調整
        .sidebar {
            position: absolute;
            z-index: 150;
        }

        // Content
        div.content.home {
            position: relative;

            // PROFILE
            section.profile-wrapper {
                position: relative;
                height: 550px;

                a.home-profile {
                    position: absolute;
                    display: block;
                    text-indent: -9999px;

                    width: 110px;
                    height: 25px;
                    right: 0;
                    top: -3px;
                    z-index: 2;

                    @include transition(background-color, .15s);

                    span {
                        position: absolute;
                        display: block;
                        width: 110px;
                        height: 25px;
                        left: 0;
                        top: 0;

                        @include transition(background-color, .3s);

                        &.profile-bg {
                            background-color: white;
                            z-index: 1;
                        }

                        &.profile-text {
                            background-color: $link-text-color-hover;
                            -webkit-mask: url(/img/button_profile_home.svg) no-repeat 0 0 / 110px 25px;
                            mask: url(/img/button_profile_home.svg) no-repeat 0 0 / 110px 25px;
                            z-index: 2;
                        }
                    }

                    &.on {
                        background-color: white;

                        span {
                            &.profile-bg {
                                background-color: $button-bg;
                            }

                            &.profile-text {
                                background-color: white;
                            }
                        }
                    }
                }

                ul.caption {
                    position: absolute;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    left: 355px;
                    top: 140px;

                    li {
                        display: block;
                        color: $sub-text-color;
                        font-size: 11px;
                        line-height: 1;

                        &.arrow {
                            padding-left: 14px;

                            &.left {
                                background: url(/img/arrow_caption_left.svg) no-repeat center left / 9px auto;
                            }

                            &.down {
                                background: url(/img/arrow_caption_down.svg) no-repeat center left / 9px auto;
                            }
                        }

                        & + li {
                            margin-top: 13px;
                        }
                    }
                }

                .photo-1 {
                    position: absolute;
                    background: url(/img/photo_komori_home_1.png) no-repeat 0 -10px;
                    background-size: cover;

                    width: 340px;
                    height: 240px;
                    left: 0;
                    top: 0;
                    z-index: 2;
                }

                .photo-2 {
                    position: absolute;
                    background: url(/img/photo_komori_home_2.png) no-repeat -55px -3px;
                    background-size: 560px auto;

                    width: 500px;
                    height: 360px;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                }
            }

            // LESSON
            section.lesson-wrapper {
                position: absolute;
                // width: 100%;
                left: 0;
                top: 605px;
                z-index: 50;

                h2.subtitle {
                    float: left;

                    background: url(/img/h2_lesson.svg) no-repeat 15px center;
                    background-size: auto 24px;

                    border-left: 8px solid $bullet-color;
                    text-indent: -9999px;

                    width: 210px;
                    height: 30px;
                }

                section.description {
                    float: left;
                    margin-top: 4px;
                    width: calc(100% - 210px);

                    h3.lesson {
                        font-size: 22px;
                        line-height: 1;
                        font-weight: 600;
                        margin-bottom: 32px;

                        a.lesson {
                            position: relative;
                            display: block;
                            color: $text-color;
                            width: 100%;
                            height: 100%;
                            @include transition(color, .15s);

                            span {
                                position: absolute;
                                display: block;

                                width: 92px;
                                height: 24px;
                                left: 347px;
                                top: 0;
                                @include transition(background-color, .3s);

                                &.more-bg {
                                    background-color: white;
                                }

                                &.more-text {
                                    background-color: $link-text-color-hover;
                                    -webkit-mask: url(/img/button_more_home.svg) no-repeat 0 0 / 92px 24px;
                                    mask: url(/img/button_more_home.svg) no-repeat 0 0 / 92px 24px;
                                    z-index: 10;
                                }
                            }

                            &.on {
                                color: $link-text-color-hover;

                                span {
                                    &.more-bg {
                                        background-color: $button-bg;
                                    }

                                    &.more-text {
                                        background-color: white;
                                    }
                                }
                            }
                        }
                    }

                    p.description {
                        font-size: 14px;
                        margin: 0;

                        & + p.description {
                            margin-top: 14px;
                        }
                    }
                }
            }

            // お問い合わせ
            section.contact {
                margin-top: 300px;
            }
        }
    }
}